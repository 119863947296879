html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
#root {
  flex: 1;
  height: 100%;
  width: 100%;
}
h4 {
  margin: 0px;
  margin-bottom: 20px;
}
.header {
  display: flex;
  border-bottom: 2px solid #11421d;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
}
.header .logo {
  display: flex;
  align-items: center;
}
.header .logo img {
  width: 76px;
}
.header .user {
  text-align: right;
}

.stack {
  display: flex;
}

.fullSize {
  height: 100%;
}

.stack.stackBetween {
  justify-content: space-between;
}
.stack.stackMiddle {
  align-items: center;
}

.stack.stackColumn {
  flex-direction: column;
}

.stack.stackEnd {
  align-items: flex-end;
}

.sidebar {
  border-right: 2px solid #11421d;
  padding: 10px;
  min-width: 240px;
  overflow-y: auto;
  padding-bottom: 100px;
}

.sidebar .section {
  margin-bottom: 40px;
}

.commandHeader {
  border-bottom: 2px solid #11421d;
  padding: 20px;
}

.rightBar {
  flex: 1;
  overflow: auto;
  padding-bottom: 200px;
}

.teams {
}
.teams .top {
  padding: 20px;
}

.teams .inscrito {
  background-color: #c5e48c;
  padding: 5px 25px;
}
.teams .noInscrito {
  background-color: #f3b6ad;
  padding: 5px 25px;
}

.teams .teamsTopBar {
  background-color: #b3b3b3;
  border: solid 1px #979797;
  padding: 5px;
}

.dialogBox {
  border: solid 1px #979797;
  margin: 50px;
  padding: 50px;
  text-align: center;
}

.formModal {
  margin: 10px 0px;
}
.formModal > div {
  margin: 10px 20px;
}
.modalContent {
  padding: 10px;
}

.teamsList .list {
  margin-top: 10px;
}
.teamsList .team .stack > span {
  padding: 10px;
}
.teamsList .team:hover,
.teamsList .team.selected {
  background-color: #d3f2af;
}

.formContainer {
  padding: 20px;
}

.logo a {
  color: black;
  text-decoration: none;
}

.updateMessage {
  max-width: 300px;
  font-size: 12px;
}
